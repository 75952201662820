.wiktionary-content h2,
h3,
h4 {
  color: var(--color-title);
  font-size: var(--text-large);
  margin: var(--gap-medium) 0;
  line-height: 1.3rem;
}

.wiktionary-content ol {
  padding-left: 16px;
  list-style: decimal;
  margin: var(--gap-small) 0;
}

.wiktionary-content ul {
  padding-left: 16px;
  list-style: disc;
  margin: var(--gap-small) 0;
}

.wiktionary-content li {
  margin: var(--gap-small) 0;
}

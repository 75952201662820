/* Generics */

:root {
  --text-large: 18px;
  --text-navigation: 16px;
  --text-medium: 15px;
  --text-small: 11px;

  --color-title: darkslategray;
  --color-default: darkslategray;
  --color-emphasis: darkslateblue;
  --color-meta: slategray;

  --gap-tiny: 4px;
  --gap-small: 8px;
  --gap-medium: 16px;
  --gap-large: 32px;

  --duration-short: 0.3s;
  --duration-long: 1.5s;
}

.grow {
  flex: 1;
}

.scroll {
  overflow-y: auto;
}

strong {
  font-weight: bold;
}

i {
  color: var(--color-emphasis);
  font-style: italic;
}

/* Typography */

.text-navigation {
  color: black;
  font-size: var(--text-navigation);
  font-weight: bold;
}

.text-title {
  color: var(--color-title);
  font-size: var(--text-large);
  font-weight: bold;
}

.text-meta {
  color: var(--color-meta);
  font-family: monospace;
  font-size: var(--text-small);
  text-transform: uppercase;
}

.text-details {
  color: var(--color-default);
  font-size: var(--text-medium);
  line-height: 1.3rem;
}

.text-error {
  color: brown;
  font-family: monospace;
  font-size: var(--text-small);
  text-transform: uppercase;
}

.link {
  color: blue;
  text-decoration: underline;
}

.link:visited {
  color: purple;
}

.link:hover {
  color: darkblue;
}

/* Page */

.page {
  background-color: lavender;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 64px calc(100vh - 72px); /* Is there a keyword solution for this? */
  grid-gap: var(--gap-small);
  height: 100vh;
  margin: auto;
  max-width: 800px;
}

.page > main {
  background-color: lavender;
  display: block;
  position: relative;
  height: 100%;
}

/* Navigation */

.navigation {
  display: flex;
  flex-direction: row;
  box-shadow: 0px 5px 5px -5px darkslategray;
}

.navigation-bottom {
  display: flex;
  flex-direction: row;
  box-shadow: 0px 5px 5px 5px darkslategray;
}

.navigation > .navigation-li {
  flex: 1;
}

.navigation-li {
  list-style: none;
}

.navigation-item {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: lavenderblush;
  border: 1px solid darkslategray;
  height: 64px;
}

.navigation-item.bottom {
  height: 80px;
}

.navigation-item:hover {
  background-color: antiquewhite;
}

.navigation-item.active {
  background-color: bisque;
}

.navigation-item:disabled {
  background-color: slategray;
}

/* Promote / Demote buttons */

.promote-button {
  background-color: powderblue;
}

.promote-button:hover {
  background-color: darkseagreen;
}

.demote-button {
  background-color: thistle;
}

.demote-button:hover {
  background-color: rosybrown;
}

/* Notifications */

.notification {
  height: 50px;
  position: relative;
  padding: var(--gap-small);

  animation-duration: var(--duration-short);
  animation-name: slidein;
  transition-duration: var(--duration-short);
  transition-timing-function: ease-out;
}

.notification > p {
  margin: auto;
}

.notification.error {
  background-color: mistyrose;
  border: 1px solid peru;
}

.notification.success {
  background-color: mediumaquamarine;
  border: 1px solid darkolivegreen;
}

@keyframes slidein {
  from {
    left: -100vw;
  }

  to {
    left: 0%;
  }
}

/* Loading */

.loading-icon {
  animation: spin infinite linear var(--duration-long),
    fadein linear var(--duration-short);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Icon buttons */

.icon-button {
  display: flex;
  align-items: center;
  justify-content: middle;
  flex-shrink: 0;
  padding: var(--gap-tiny);
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 8px;
}

.icon-button:hover {
  background-color: lavenderblush;
  border: 1px solid slategray;
}

/* Content organization */

.content-card {
  background-color: lavender;
  border: 1px solid darkslategray;
  box-shadow: 0px 5px 5px -5px darkslategray;
  padding: var(--gap-medium);
}

.content-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item-list {
  padding-left: 16px;
  list-style: disc;
  margin: var(--gap-small) 0;
}

.content-list {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content-list > * + * {
  margin-top: var(--gap-medium);
}

.content-text {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content-query {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0 var(--gap-medium);
}

.content-query > select {
  padding: var(--gap-small);
}

.content-query > input {
  padding: var(--gap-small);
}

.content-query > label {
  margin-top: var(--gap-medium);
  margin-bottom: var(--gap-small);
}

.content-query :first {
  margin-top: 100px;
}

/* Margins and paddings */

.padding-small {
  padding: var(--gap-small) var(--gap-medium);
}

.margin-small {
  margin: var(--gap-small) 0px;
}

.margin-title {
  margin: var(--gap-large) 0px var(--gap-small);
}

.margin-paragraph {
  margin: var(--gap-medium) 0px var(--gap-small);
}

/* Expression set page */

.page-loading {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-with-padding {
  position: relative;
  padding: var(--gap-small) var(--gap-medium) var(--gap-medium);
}

.page-with-bottom-navigation {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: calc(100% - 88px) 80px;
  grid-gap: var(--gap-small);
  height: 100%;
}

.loading-overlay {
  position: absolute;
  animation: fadein linear var(--duration-short);
  background-color: lavender;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
